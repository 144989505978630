<template>
	<div class="page-container">
		<div class="header" style="display: none">
			<page-header @navBack="navBack" :back="false" :pageTitle="pageTitle">
				<!-- <template v-slot:default>
				  <van-icon size="20" name="question-o" />
				</template> -->
			</page-header>
		</div>

		<div class="page-body" style="padding-top:30px;">
			<div class="content">
				<div class="content-wrap">
					<div class="desc-item">
						<span class="c-main">{{testInfo.passCount}}</span>
						<span>合格次数</span>
					</div>
					<div class="line"></div>
					<div class="desc-item">
						<span class="c-main">{{testInfo.testCount}}</span>
						<span>累计考试次数</span>
					</div>
					<div class="line"></div>
					<div class="desc-item">
						<span class="c-main">{{testInfo.totalUnSubmitCount}}</span>
						<span>未做题数</span>
					</div>
				</div>
				<div ref="chart" class="chart-wrap">
					<div class="tips"><span>及格线</span><span>{{examinationPass}}</span><span>分</span></div>
					<canvas id="scoreChart" :width="chartWidth" height="220"></canvas>
				</div>
				<div class="chart-desc">近10次模拟考试成绩</div>
			</div>

			<div class="record">
				<div class="title s-17 line">历史成绩</div>
				<van-list
					v-model="loading"
					:finished="finished"
					finished-text="没有更多了"
					@load="getScoreList"
				>
					<!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
					<div class="table" v-if="testInfo.list">
						<!-- <div class="table-list hd">
						  <span class="item">时间</span>
						  <span class="item">学时</span>
						  <span class="item">状态</span>
						</div> -->
						<div
							v-for="(item, index) of testInfo.list"
							:key="index"
							class="table-list"
						>
							<span class="item c-main">{{ item.score }}分</span>
							<span class="item">{{ item.testDuration }}</span>
							<span
								:class="[
                  'item'
                ]"
							>{{ item.createTime }}</span
							>
						</div>
					</div>
				</van-list>
				<div class="confirm-wrap text-center">
					<van-button
						v-if="ifDownload"
						class="btn"
						type="primary"
						style="top:-15px"
						block
						@click="showCertificatePage"
					>去下载合格证明
					</van-button>
					<van-button @click="confirmAction" class="btn" type="primary">
						{{(testInfo.list&&testInfo.list.length)?'确定':'去考试'}}
					</van-button>
					
				</div>
			</div>

		</div>

		<van-popup round v-model="showReset">
			<div class="popup-reset">
				<div class="content">
					确定重置学时吗？
				</div>
				<div class="btn-wrap">
					<van-button @click="cancle" class="btn default" type="primary">取消</van-button>
					<van-button @click="cancle" class="btn" type="primary">确定</van-button>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import PageHeader from "../components/page-header.vue";
	import {getScoreList} from '../api/user';
	import {transToMinWithUnit} from '@/utils/index';
	import { getPlanInfo,getstudyTotal} from '../api/user';

	const F2 = require('@antv/f2/lib/index')
	export default {
		data() {
			return {
				pageTitle: "我的成绩",
				showRate: false,
				showPop0: false,
				showPop: false,
				loading: false, //
				finished: false, // 签到列表
				showReset: false, // 重置学时
				isSupportSubject: true, // 是否支持科目一、四
				pageSize: 10,
				currentPage: 1,
				subject: 1,
				serviceRate: {
					studentId: '',
					serviceAwareness: 5,
					serviceAbility: 5,
					serviceEfficiency: 5,
				},
				chartWidth: 300,
				testInfo: {},
				scoreList: [],
				examinationPass:0,
				// navHeight  tabbarHeight
				navHeight: 0,
				tabbarHeight: 0,
				token: "",
				appno: "",
				ifDownload:false,
				belongOrganization:"",
				colorMain: "#ff8506",
				colorSecond: "#fca142",
			};
		},
		computed: {
			userid() {
				return this.$store.state.user.userid
			},
			categoryid() {
				return this.$store.state.user.categoryid
			},
		},
		components: {
			PageHeader,
		},
		mounted() {
			this.getPlanInfo();
			this.getUrlParams();

			// chart
			this.chartWidth = this.$refs.chart.clientWidth || this.$refs.chart.offsetWidth
		},
		methods: {
			getPlanInfo() {
				this.examinationPass = localStorage.getItem("examinationPass");
				getPlanInfo(this.userid).then(res => {
					// console.log('res',res);
					this.belongOrganization = res.belongOrganization;
					// this.belongOrganization = '13';
					if (this.belongOrganization === '13') {
						this.ifDownload = true;
					}
				})
			},
				showCertificatePage() {
				if (this.belongOrganization === '13') {
					// 成绩汇总
					getstudyTotal(this.userid).then(res => {
						console.log(res);
						this.navBack();
					}).catch(() =>{})
				}
				const appno = sessionStorage.getItem('appno');
				// const cityCode = getCityCode();
				this.$router.push({
						path: '/studyCertificateList/' + this.userid,
						query: { appNo: appno }
					});
			},
			check() {
				// this.showRate = true;
			},
			judgeApp(appno) {
				switch (appno) {
					case 'APP02':
						this.colorMain = '#2db1ac';
						this.colorSecond = "#96cdfa";
						this.gradientColor = {
							"0%": "#96cdfa",
							"100%": "#2db1ac",
						}
						this.isSupportSubject = false;
						break;
					case 'APP03':
						this.colorMain = '#1175fe';
						this.colorSecond = "#b1ffee";
						this.gradientColor = {
							"0%": "#b1ffee",
							"100%": "#1175fe",
						}
						this.isSupportSubject = false;
						break;
					case 'APP04':
						this.colorMain = '#ff8506';
						this.gradientColor = {
							"0%": "#FFE1C1",
							"100%": "#FF8506",
						}
						this.isSupportSubject = false;
						break;
					case 'APP05':
						this.colorMain = '#ff8506';
						this.gradientColor = {
							"0%": "#FFE1C1",
							"100%": "#FF8506",
						}
						this.isSupportSubject = false;
						break;
					case 'APP06':
						this.colorMain = '#ff8506';
						this.gradientColor = {
							"0%": "#FFE1C1",
							"100%": "#FF8506",
						}
						this.isSupportSubject = false;
						break;
					case 'APP08':
						this.colorMain = '#ff8506';
						this.gradientColor = {
							"0%": "#FFE1C1",
							"100%": "#FF8506",
						}
						this.isSupportSubject = false;
						break;
					default:
						this.colorMain = '#ff8506';
						this.colorSecond = "#fca142";
						this.gradientColor = {
							"0%": "#FFE1C1",
							"100%": "#FF8506",
						}
						this.isSupportSubject = true;
						break;
				}
			},
			getUrlParams() {
				// http://ip-address:8084/#/?navHeight=88&tabbarHeight=83&token=eyJh
				let params = window.location.hash.split("?")[1];
				let singleParams = [];
				if (params) singleParams = params.split("&");
				if (singleParams && singleParams.length) {
					singleParams.map((v) => {
						let value = v.split("=")[1];
						if (v.indexOf("subject") != -1) {
							this.navHeight = value;
						}
						if (v.indexOf("appNo") != -1 || v.indexOf("appno") != -1) {
							this.appno = value || sessionStorage.getItem("appno");
							sessionStorage.setItem("appno", this.appno);
							let no =
								this.appno == "APP01" ? "1" : this.appno == "APP02" ? "2" : "3";
							this.$store.dispatch("setAppno", no);

							this.judgeApp(this.appno)
						}
						if (v.indexOf("token") != -1) {
							this.token = value || sessionStorage.getItem("token");
						}
					});
					if (this.token) {
						this.toLogin({appNo: this.appno || "APP01", token: this.token});
					} else {
						const appno = sessionStorage.getItem('appno');
						this.judgeApp(appno);

						this.subject = this.$route.query.subject;
					}
				}
			},
			toLogin(data) {
				this.$store
					.dispatch("Login", data)
					.then((res) => {
						console.log('score 登录 ===>', res)
						// this.$store
						// .dispatch("GetInfo")
						// .then(() => {
						// this.$router.push({ path: "/train" });
						// })
					})
					.catch(() => {
						// this.$message.error(err);
					});
			},
			getScoreList() {
				// studentId categoryId  subject
				this.loading = true;
				getScoreList({
					studentId: this.userid,
					categoryId: this.categoryid,
					subject: this.subject,
				}).then((res) => {
					this.testInfo = res;
					const list = res ? res.list : [];
					list.map((val, idx) => {
						val.day = idx + '';
						val.value = val.score;
						val.testDuration = transToMinWithUnit(val.testDuration);
						val.createTime = val.createTime.split(' ')[0]
					})
					this.scoreList = list.slice(0, 10);
					this.loading = false;
					this.finished = true;

					this.renderChart();
				}).catch(() => {
					this.loading = false;
					this.finished = true;
				})
			},
			cancle() {
				this.showRate = false;
				this.showReset = false;
			},
			confirmAction() {
				this.navBack();
				// if(this.testInfo.list&&this.testInfo.list.length) {
				// }else{
				// }
			},
			navBack() {
				this.$router.push({path: '/test', query: {subject: this.subject}})
			},
			renderChart() {
				const that = this;

				const chart = new F2.Chart({
					id: 'scoreChart',
					pixelRatio: window.devicePixelRatio
				});

				chart.source(this.scoreList, {
					value: {
						tickCount: 5,
						min: 0
					},
					day: {
						range: [0, 1]
					}
				});
				chart.tooltip({
					showCrosshairs: true,
					showItemMarker: false,
					tooltipMarkerStyle: {
						stroke: that.colorMain,
						fill: that.colorMain, // 设置 tooltipMarker 的样式
					},
					onShow: function onShow(ev) {
						const items = ev.items;
						items[0].name = '成绩';
						items[0].value = items[0].value + '分';
					}
				});
				chart.axis('day', {
					line: {
						stroke: that.colorMain,
						lineWidth: 2,
						top: true, // 展示在最上层
					},
					label: function label(text, index, total) {
						const textCfg = {};
						if (index === 0) {
							textCfg.textAlign = 'left';
						} else if (index === total - 1) {
							textCfg.textAlign = 'right';
						}
						return textCfg;
					}
				});
				chart.axis('value', {
					line: {
						stroke: that.colorMain,
						lineWidth: 2,
						top: true, // 展示在最上层
					}
				});
				chart.line().position('day*value').shape('smooth').style({
					stroke: that.colorMain,
				});
				chart.point().position('day*value').shape('smooth').style({
					stroke: that.colorMain,
					fillStyle: "#fff",
					lineWidth: 1
				});
				chart.render();
			}
		},
	};
</script>

<style lang="less" scoped>
	.header {
		position: absolute;
		width: 100%;
		height: 200px;
		padding-bottom: 14px;
		border-bottom-left-radius: 26px 10px;
		border-bottom-right-radius: 26px 10px;
	}

	.page-container {
		min-height: 100vh;
		background: #f5f5f5;
	}

	.page-body {
		margin-top: 0;
		padding: 100px 15px 15px;

		.content {
			position: relative;
			top: -14px;
			background: #fff;
			border-radius: 14px;
			padding: 15px;

			.content-wrap {
				display: flex;
				justify-content: space-between;
				padding: 20px 0;

				.desc-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 16px;
					text-align: center;
					color: #666;

					span:first-of-type {
						font-size: 24px;
						margin-bottom: 10px;
					}
				}

				.line {
					width: 1px;
					height: 24px;
					background: #ddd;
					position: relative;
					top: 24px;
				}
			}

			.chart-wrap {
				position: relative;
				width: 100%;
				padding: 20px 0;
				// height: 220px;
				background: #fafaf9;

				.tips {
					position: absolute;
					right: 6px;
					top: 6px;
					font-size: 10px;
					color: #666;
				}
			}

			.chart-desc {
				text-align: center;
				color: #666;
				font-size: 12px;
				margin-top: 10px;
			}

		}

		.record {
			padding: 15px;
			background: #ffffff;
			border-radius: 14px;

			.table {
				margin-top: 24px;
				max-height: 300px;
				overflow-y: auto;

				.table-list {
					display: flex;
					align-items: center;
					color: #999;

					& + .table-list {
						margin-top: 8px;
					}

					.item {
						font-size: 13px;
						flex: 0 0 33.333%;
						text-align: center;
						// &:first-of-type {
						//   flex: 0 0 40%;
						//   text-align: left;
						// }
						&.grey {
							color: #999;
						}

						&.red {
							color: #ff300e;
						}

						&.orange {
							color: #ff8506;
						}
					}

					&.hd {
						.item {
							font-size: 16px;
						}
					}
				}
			}
		}

		.confirm-wrap {
			margin-top: 20px;
		}
	}

	// .van-popup {
	//   background: transparent;
	// }

	.van-popup .van-popover__arrow {
		background: #fca142;
	}

	.van-popover__content {
		background: #fca142;
		color: #fff;
	}

	.van-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.circle-wrap {
		position: relative;
		height: 200px;

		.circle {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 160px;
			height: 160px;
			margin: -80px 0 0 -80px;
			background-image: url(~@/assets/icon-circle-sm.png);
			background-size: cover;

			.circle-inner {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;

				.title {
					margin: 0 0 4px 0;
					color: #ff8506;
					font-size: 20px;
				}

				span {
					color: #999;
					font-size: 11px;
				}
			}

		}
	}

	.popup-reset {
		width: 290px;
		padding: 20px;

		.content {
			line-height: 1.8;
			margin-bottom: 20px;
		}

		.btn-wrap {
			display: flex;
			justify-content: space-evenly;
			width: 100%;

			.btn {
				width: 40%;
			}
		}
	}

	.popup-content {
		width: 290px;
		// border-radius: 12px;
		overflow: hidden;
		background: #fff;

		& > .title {
			background: linear-gradient(90deg, #fca142, #ff8506);
			padding: 15px;
			font-size: 18px;
			color: #fff;
			text-align: center;
		}

		.content {
			margin: 20px 0 10px;
			padding: 0 10px;
			font-size: 12px;

			.list {
				display: flex;
				align-items: center;
				margin-top: 10px;

				span {
					display: inline-block;
					width: 50%;
				}
			}
		}

		.btn-wrap {
			display: flex;
			padding: 15px;

			.btn {
				width: 40%;
				font-weight: 500;
			}
		}
	}

	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;

		.circle-wrap {
			.circle {
				.circle-inner {
					.title {
						color: @colorMain;
					}
				}

			}
		}
	}

	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;

		.circle-wrap {
			.circle {
				.circle-inner {
					.title {
						color: @colorMain;
					}
				}

			}
		}
	}
</style>
